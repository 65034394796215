<template>
  <page :styleWrap="{background: '#fff'}" :state='pageState' :footer='60'>
    <div>
      <header-bar>
        <div slot='leading' @click="$router.back()">
          <i style="font-size: 20px" class="iconfont icon-back"></i>
        </div>
      </header-bar>
      <div class="labelPage" v-if='labelList.length'>
        <div v-for='(item, index) in labelList' :key='index'>
          <div class="title">{{item.labelGroupName}}</div>
          <div class="labelPanel f_flex f_a_c f_w">
            <div 
              class="labelItem f_flex f_a_c f_j_c f_s0 f_g1 labelItemMargin"
              v-for='(ii, kk) in item.labelList' 
              :key='kk'>
              <p class="line_2">{{ii.labelName}}</p>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <empty description="还没有标签哦"></empty>
      </div>
      <div class="footerPanel"></div>
    </div>
    

    <div slot='footer' style="background: #fff; height: 60px; padding-top: 5px">
      <button 
        class="btn btn_radius theme_bg margin_auto" 
        @click="$router.back()" 
        style="width: 80%">
        确定
      </button>
    </div>
  </page>
</template>

<script>
import groupApi from '@/api/group'
import { Empty } from 'vant';

export default {
  data: ()=> ({
    labelList: [],
    status: 0,
    pageState: 'loading'
  }),
  components: {
    Empty
  },
  methods: {
    getList() {
      groupApi.groupLabelList({
        groupId: this.$route.query.groupId
      }).then(res => {
        if (res.data) {
          this.status = res.data.operateStatus
          this.labelList = res.data.labelGroupList
        }
        this.pageState = 'success'
      })
    }
  },
  mounted() {
    this.getList()
    this.$route.meta.change = ()=> {
      this.getList()
    }
  }
}
</script>

<style lang="less" scoped>
.labelPage {
  position: relative;
  padding: 15px;
  .title {
    font-size: 16px;
    margin-bottom: 9px;
    color: #434343;
  }
}
.labelPanel {
  margin-bottom: 20px;
}
.labelItem {
  width: 108px;
  height: 40px;
  box-sizing: border-box;
  background: #F1F1F1;
  color: #2D2D2D;
  padding: 0 13px;
  margin-bottom: 8px;
}
.labelItemMargin {
  margin-right: 10px;
}
.line_2 {
  width: 100%;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  line-height: 14px;
  font-size: 14px;
  text-align: center;
}
</style>